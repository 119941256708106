<template>
  <v-container>
    <v-row align="center">
      <v-col>
        <SearchBox v-model="search" @plusClicked="newMembershipType">
          <template v-slot:plus>

            <v-menu bottom :close-on-click="true" :rounded="true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab x-small color="primary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item link @click="newMembershipType('SingleSession')">
                  <v-list-item-title><v-icon small class="mr-2">mdi-numeric-1-box</v-icon>Séance
                    unique</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="newMembershipType('Card')">
                  <v-list-item-title><v-icon small class="mr-2">mdi-numeric</v-icon>Carte abonnement</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="newMembershipType('Unlimited')">
                  <v-list-item-title><v-icon small class="mr-2">mdi-infinity</v-icon>Abonnement
                    illimité</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

          </template>
        </SearchBox>
      </v-col>
      <v-col md="3" v-if="membershipTypesForUnit.length > 0">
        <v-radio-group v-model="searchActive" row>
          <v-chip class="pl-0 pr-0" color="orange lighten-4" outlined link>
            <v-chip :color="searchActive ? '' : 'deep-orange lighten-2'" class="font-weight-bold text-body-1 white--text"
              link @click="searchActive = false">
              {{ membershipTypesForUnit.filter((mt) => !mt.active).length }}
              inactifs</v-chip>
            <v-switch class="ml-3" inset v-model="searchActive" />

            <v-chip :color="searchActive ? 'deep-orange lighten-2' : ''" class="font-weight-bold text-body-1 white--text"
              @click="searchActive = true">
              {{ membershipTypesForUnit.filter((mt) => mt.active).length }}
              actifs</v-chip>
          </v-chip>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pl-0 pr-0">

        <v-data-table :headers="[

          { text: 'Nom', value: 'name' },
          { text: 'Nombre de séances', value: 'unit' },
          { text: 'Prix TVAC', value: 'priceVatIncluded' },
          { text: 'Actions', value: 'actions', sortable: false, },
        ]" :items="membershipTypesForUnit.filter((type) => type.active == searchActive)" :search="search"
          :loading="loading" :hide-default-footer="true" disable-pagination :sort-by="sortBy" :sort-desc="sortDesc">

          <template v-slot:item.name="{ item }">
            {{ item.name }}

            <v-chip v-if="item.active == 0" color="red" small text-color="white" class="ml-2">{{
              $t("inactive")
            }}</v-chip>
          </template>

          <template v-slot:item.unit="{ item }">
            <span v-if="item.unit">{{ item.unit }}</span>
            <span v-else>Illimité</span>
          </template>

          <template v-slot:item.priceVatIncluded="{ item }">
            <span v-if="item.priceVatIncluded > 0">{{ item.priceVatIncluded }} €</span>
            <span v-else>Gratuit</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="editMembershipType(item)" v-bind="attrs" v-on="on" icon>
                  <v-icon>
                    mdi-pencil-box
                  </v-icon>
                </v-btn>
              </template>
              <span>Modifier l'abonnement</span>
            </v-tooltip>

          </template>
          <template v-slot:no-data>
            <NoTableData @click="newMembershipType" />
          </template>
          <template v-slot:loading>
            <Loader />
          </template>
        </v-data-table>

      </v-col>
    </v-row>

    <MembershipTypeEditionPopup ref="membershipTypeEdition" @onSaveClicked="saveClicked" @onCloseClicked="retrieveAll" />
  </v-container>
</template>

<script>
// import MembershipTypeCard from "@/components/Admin/MembershipType/MembershipTypeCard";
import MembershipTypeEditionPopup from "@/components/Admin/MembershipType/MembershipTypeEditionPopup";
import SearchBox from "@/components/Admin/SearchBox";
import Loader from "@/components/Admin/Loader";
import NoTableData from "@/components/Admin/NoTableData.vue";

export default {
  props: {
    unitType: String,
  },
  components: {
    // MembershipTypeCard,
    MembershipTypeEditionPopup,
    SearchBox,
    Loader,
    NoTableData,
  },
  data() {
    return {
      loading: false,
      itemsPerPageArray: [4, 8, 12],
      search: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      sortBy: "name",
      membershipTypes: [],
      searchActive: true,
    };
  },
  computed: {
    membershipTypesForUnit() {
      switch (this.unitType) {
        case "SingleSession":
          return this.membershipTypes?.filter((type) => type.unit == 1) ?? [];
        case "Card":
          return this.membershipTypes?.filter((type) => type.unit > 1) ?? [];
        case "Unlimited":
          return (
            this.membershipTypes?.filter((type) => type.unit == null) ?? []
          );
        default:
          return this.membershipTypes ?? [];
      }
    },
    numberOfPages() {
      return Math.ceil(this.membershipTypes.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== `Name`);
    },
  },
  watch: {
    unitType: function () {
      this.search = null;
      this.searchActive = true;
    },
  },
  methods: {
    customFilter(items, search) {
      if (!search) return items.filter((c) => c.active === this.searchActive);

      search = (search ?? "").toLowerCase();

      const filteredItems = items.filter(
        (element) =>
          element.active === this.searchActive &&
          element.name.toLowerCase().includes(search)
      );

      return filteredItems;
    },
    newMembershipType(unitType) {
      let unit;

      switch (unitType ?? this.unitType) {
        case "SingleSession":
          unit = 1;
          break;
        case "Card":
          unit = 10;
          break;
        case "Unlimited":
          unit = null;
          break;
        default:
          unit = null;
          break;
      }

      this.$refs.membershipTypeEdition.open({
        active: true,
        vatRate: "21",
        unit: unit,
      });
    },
    editMembershipType(membershipType) {
      var value = JSON.parse(JSON.stringify(membershipType));
      this.$refs.membershipTypeEdition.open(value);
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    saveClicked() {
      this.retrieveAll();
    },
    retrieveMembershipTypes() {
      this.loading = true;
      this.$services.membershipType
        .getAll()
        .then((response) => {
          console.dir(response.data);
          this.membershipTypes = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$helpers.snackbar.handleError(error);
        });
    },
    retrieveAll() {
      this.retrieveMembershipTypes();
    },
  },
  mounted() {
    this.retrieveAll();
  },
};
</script>